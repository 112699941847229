@font-face {
  font-family: 'Product Sans';
  src: local('ProductSans'), url(./newUXAssets/fonts/ProductSans.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./newUXAssets/fonts/Inter.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}